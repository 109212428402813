import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import swal from "sweetalert";

import instance from "../../services/axios";

const VerifyAccount = () => {
  const { code } = useParams();
  const history = useHistory();

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const res = await instance.get(`/api/v1/verify-data-user/${code}`);
        swal({
          text: res.data.msg,
          icon: "success",
          buttons: false,
          timer: 5000,
        });
      } catch (error) {
        console.error(error);
        swal({
          text: error.response.data.message,
          icon: "error",
          buttons: false,
          timer: 5000,
        });
      } finally {
        history.push("/signin");
      }
    };
    verifyEmail();
  }, [code, history]);

  return (
    <>
      <span>sukses verifikasi, silahkan login</span>
    </>
  );
};

export default VerifyAccount;
